;(function ($) {

    // FastClick.attach(document.body);

    function closeCountry(selector) {
        var country = $('.' + selector + ''),
            countryHeight = country.height(),
            height = 0;

        $('._menu_wrapper').removeClass('active');
        country.toggleClass('open');
        country.slideToggle(500);
        $('.country_trigger').toggleClass('-active');
        $('._menu_trigger').removeClass('expand');
        if (country.hasClass('open')) {
            height = countryHeight; 
        } else {
            height = 0
        }
        $('body').animate({
            backgroundPositionY: '' + height + 'px'
        }, 500); 
    }

    var PopupBuy = {
        assignBuyPopup: function() {

            var buyBtn = $('a.btn.buy'),
                productWrapper = buyBtn.closest('.product-page'),
                color = this.checkClass(productWrapper);

            buyBtn.attr('data-open','popup-buy-'+ color +'');

        },
        checkClass: function(obj) {

            if(obj.hasClass('chestyCough')) return 'orange';
            if(obj.hasClass('naturalDry')) return 'green';
            if(obj.hasClass('bisolsek')) return 'blue';
            if(obj.hasClass('coldAndFlu')) return 'red';
            if(obj.hasClass('bisolnasal')) return 'light-green';
        }
    };

    function textContent() {
        var imgHeight = $('.product_info .content_info img').height();
        if (window.innerWidth < 768){
            $('.text-content').css('height', '50px');
        } else {
            $('.text-content').css('height', imgHeight + 'px');
        }

    }

    function bgBody() {
        if (window.innerWidth > 768) {
            setTimeout(function () {
                $('body').css('background-color', '#ee8b38')
            }, 500)
        } else {
            $('body').css('background-color', '#fff')
        }
    }

    function tabsHeight() {
        if ($('.tabs').length) {
            var biggerTab = [],
                biggerTabContent = [],
                biggerTabTitle = [];

            $('.tab').each(function () {
                biggerTab.push($(this).outerHeight());
                biggerTabContent.push($(this).find('.tab-content').outerHeight());
                biggerTabTitle.push($(this).find('.tab-title').outerHeight());
            });

            biggerTab = Math.max(...biggerTab);
            biggerTabContent = Math.max(...biggerTabContent);
            biggerTabTitle = Math.max(...biggerTabTitle);
            $('.tabs').css('height', biggerTab + biggerTabContent);
            $('.tab-content').css('height', biggerTabContent + 'px').css('top', biggerTabTitle + 'px').addClass('tabs-height');
            $('.tab-title').css('height', biggerTabTitle);
        }
    }

    function checkActive() {
        $('.tab-content').hide();
        $('.tab-title.active').siblings('.tab-content').show();
    }

    $(window).load(function () {

        var countrySelect = document.querySelector('#country');

        if(countrySelect) countrySelect.addEventListener('change', function(e){e.target.blur()}, false);

        $('.item').matchHeight();
         tabsHeight();

        textContent();

        if (window.innerWidth < 768) {
            $('.tab-content').show().css('height', 'auto');
        }
        if (window.innerWidth > 768) {

           // bgBody();
        }

        PopupBuy.assignBuyPopup();
    });


    $(document).ready(function () {


        FastClick.attach(document.body);

        $(document).foundation();
        $('.close-button').click(function () {
            var current = $(this).prev().find('iframe');
            current.each(function() {
                this.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
                this.contentWindow.postMessage('{"method":"pause","value":""}', '*');
            });
            var player = new Vimeo.Player(current);
            player.pause();

        });
        //cookies
        $('#cookie_agree').on('click', function () {
            $.cookie('cookie-info', '1', {expires: 365});
            $('.cookie_container').fadeOut();
        });
        $('#cookie_cancel').on('click', function () {
            $('.cookie_container').fadeOut();
        });
        if (!parseInt($.cookie('cookie-info'))) {
            $('.cookie_container').show();
        }
        ;

        var popup = {
            open: function (param) {
                $('#disclaimer_continue').attr('href', param);
                $('.disclaimer_overlay').fadeIn("slow");
            },
            close: function () {
                $('.disclaimer_overlay').fadeOut("slow");
            }
        };
        $('.disclaimer-popup').off('click').off().on('click', function (e) {
            e.preventDefault();
            popup.open($(e.currentTarget).attr('href'));
        });
        $('.disclaimer_close_mobile,.disclaimer_close,#disclaimer_close_button').on('click', function () {
            popup.close()
        });

        checkActive();

        $('#important_trigger').on('click', function () {
            $('.important_popup').fadeToggle();
            $(this).toggleClass('active');
        });

        $('#cookies_anchor').on('click', function () {
            $('html, body').animate({scrollTop: $('#Cookies').offset().top}, '600');
        });

        // products slider - home page
        $('.grid-home .view_button').on('click touchstart', function (e) {

            var target = $(e.target),
                data = target.data('name'),
                color = target.css('background-color'),
                carousel = $('.view-products-carousel'),
                triangleWrapper = carousel.find('.triangle-wrapper'),
                currentCarousel = $('.' + data + '').find('.carousel-product');

            if (window.innerWidth < 768 || !data) return;

            e.preventDefault();

            $('.view_button').removeClass('arrow-rotate');

            target.addClass('arrow-rotate');

            triangleWrapper.css('left', ((((target.position().left) + (triangleWrapper.width() / 2)) / (carousel.innerWidth())) * 100) + '%');

            carousel
                .find('.view-all')
                .css('background-color', color)
                .end().css({'border-color': color})
                .find('.prodek')
                .css({'display': 'none'})
                .toggleClass('visible-view-products')
                .css({'border-color': color})
                .end().find('.-one')
                .css('border-bottom-color', color)
                .end().find('.' + data + '')
                .css('display', 'block')
                .find('h3, span')
                .css({'color': color});

            currentCarousel
                .not('.slick-initialized').slick({
                infinite: true,
                arrows: true,
                slidesToScroll: 4,
                slidesToShow: 4,
                adaptiveHeight: true,
            });

            currentCarousel.slick('setPosition')
        });

        // main slider
        $('.carousel').slick({
            dots: true,
            arrows: true,
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            adaptiveHeight: false,
            autoplay: true,
            autoplaySpeed: 5000
        });


        $('.country_trigger, .country_close').on('click', function (e) {
            e.preventDefault();
            closeCountry('country');
        });

        $('.info_close').on('click', function (e) {
            var parent = $(e.target).parent();
            parent.fadeToggle();
            $('#important_trigger').toggleClass('active')
        });

        // search mobile input placeholder
        inputPlaceholder({inputClass: '.placeholder', placeholderClass: 'placeholder-p'});

        // search input value and submit
        var searchForm = document.querySelectorAll('.search-form');
        [].forEach.call(searchForm, function (form) {
            form.addEventListener('submit', function (e) {
                e.preventDefault();

                var input = this.querySelector('input');
                if (input.value != '') {
                    this.submit();
                }
            }, false);
        }, false);

        $('.tab-title').on('click', function () {
            if (window.innerWidth >= 768) {
                $('.tab-content').hide();
                $(this).siblings('.tab-content').show();
                $('.tab-title').removeClass('active');
                $(this).addClass('active');
            }
        });

        if (window.innerWidth >= 768) {
            setTimeout(function () {
                tabsHeight();
            }, 50);

            var inside = $('._menu_list_inside li a');
            inside.on('click', function (e) {
                e.stopPropagation();
            });

            $('._menu_desktop_trigger')
                .on('mouseenter', function () {
                    var that = $(this),
                        sibling = that.siblings('._menu_desktop_trigger');

                    sibling.removeClass('active border-btm');
                    // sibling.children('._menu_list_inside').slideUp('fast');
                    that.children('._menu_list_inside').stop().slideToggle('fast', function() {
                        $(this).addClass('border-btm');
                    });
                    that.addClass('active');

                })
                .on('mouseleave', function(e){
                    var that = $(this),
                        sibling = that.siblings('._menu_desktop_trigger');

                    sibling.removeClass('active border-btm');
                    // sibling.children('._menu_list_inside').slideUp('fast');
                    that.children('._menu_list_inside').stop().slideToggle("fast", function() {

                    });
                    that.removeClass('active');
                })
                .on('touchstart', function (e) {
                    'use strict';
                    var link = $(this);
                    if (link.hasClass('active')) {
                        link.removeClass('active');
                        link.find('ul').hide();
                        return true;
                    } else {
                        link.addClass('active');
                        link.parent().find('ul').hide();
                        link.find('ul').show();
                        $('._menu_desktop_trigger').not(this).removeClass('active');
                        e.preventDefault();
                        return false;
                    }
                });
        } else {
            $('.tabs').css('height');
        } 


        $(window).on('resize', function () {

            //bgBody();

            checkActive();
            textContent();
            if (window.innerWidth < 768) {
                $('.tab-content').show().css({'height': 'auto'});
                $('.btn.buy').hide();
                $('._menu_desktop_trigger').unbind('mouseenter mouseleave');
            } else {

                tabsHeight();
                $('.btn.buy').show();
            }
        });

        $('._menu_trigger').on('click', function () {
            $('._menu_wrapper').toggleClass("active");
            $(this).toggleClass("expand");
        });

        if (window.innerWidth < 768) {
            $('._menu_list_header').on('click', function () {
                $(this).toggleClass("active");
                $(this).siblings('._menu_list_inside,.important_information_inside').slideToggle('slow');
            });

            $('._menu_img img').on('click', function () {
                var target = $(this).attr('data-target');
                if (target && target.length > 0) {
                    // $('#' + target).toggleClass("active");
                    $('#' + target).siblings('._menu_list_inside,.important_information_inside').slideToggle('slow');
                }
            });
        }


        $('.accordion_nav').on('click touch', function () {
            $(this).siblings('.accordion_panel').slideToggle('slow');
            $(this).toggleClass('active');
        });

        //Promoties Page
        $('.thumb_checkbox').on('click',function(){
            if($(this).siblings().hasClass('checked'))
                $(this).siblings().removeClass('checked');
            var thumbID=$(this).attr('id');
            $('label[for='+thumbID+']').toggleClass('checked');
        });
        $('.thumb_checkbox:checked').click();
        
        $('.promoties_open_popup').on('click',function(){
            $('.input_popup_require').removeAttr('disabled');
        });

    });

})(jQuery);


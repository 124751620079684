// created by Kowal 2016.06.06

;(function () {
    function Placeholder() {

        this.elemToWrap = null;
        this.elemToWrapHeight = null;
        this.elemToWrapText = null;

        this.options = {
            inputClass: '',
            placeholderWrapper: 'div',
            placeholderText: '',
            placeholderClass: '',
            placeholderFontSize: ''
        };

        if (arguments[0] && typeof arguments[0] === "object") {
            extendDefaults(this.options, arguments[0]);
        }

        this.init(this.options.inputClass);
    }

    Placeholder.prototype.init = function (cssClass) {
        wrap.call(this, cssClass);
    };

    function wrap(cssClass, wrapper) {
        setElemToWrap.call(this, cssClass);
        wrapper = wrapper || document.createElement('div');
        wrapper.style.position = 'relative';
        var placeholder = createPlaceholder.call(this);

        wrapper.addEventListener('click', function () {
            placeholder.style.display = 'none';
            this.elemToWrap.focus();
        }.bind(this));

        this.elemToWrap.addEventListener('blur', function () {
            if (this.elemToWrap.value == '')
                placeholder.style.display = 'block';
        }.bind(this));

        if (this.elemToWrap.nextSibling) {
            this.elemToWrap.parentNode.insertBefore(wrapper, this.elemToWrap.nextSibling);
        } else {
            this.elemToWrap.parentNode.appendChild(wrapper);
        }
        wrapper.appendChild(placeholder);

        return wrapper.appendChild(this.elemToWrap);
    }

    function setElemToWrap(elem) {
        this.elemToWrap = document.querySelector('' + elem + '');
        this.elemToWrapHeight = parseInt(window.getComputedStyle(this.elemToWrap, null).getPropertyValue("height"));
        this.elemToWrapText = this.options.placeholderText || this.elemToWrap.getAttribute('data-placeholder-text');
    }

    function createPlaceholder() {
        var placeholder = document.createElement(this.options.placeholderWrapper);
        placeholder.style.fontSize = this.placeholderFontSize || (this.elemToWrapHeight - (this.elemToWrapHeight / 2)) + 'px';
        placeholder.textContent = this.elemToWrapText;
        placeholder.style.position = 'absolute';
        placeholder.style.lineHeight = this.elemToWrapHeight + 'px';
        placeholder.style.top = '0px';
        placeholder.style.left = '5px';
        placeholder.style.zIndex = '999';
        placeholder.className = this.options.placeholderClass;
        return placeholder;
    }

    function extendDefaults(property) {
        var prop = property || {},
            argsLength = arguments.length;
        for (var i = 1; i < argsLength; i++) {
            if (!arguments[i])
                continue;
            for (var key in arguments[i]) {
                if (arguments[i].hasOwnProperty(key))
                    prop[key] = arguments[i][key];
            }
        }
        return prop;
    }

    window.inputPlaceholder = function (selector) {
        return new Placeholder(selector);
    };
})();


